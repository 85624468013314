  .apply-background2 {
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../resources/koro.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    z-index: 0; /* Ensure it sits behind the content */
    opacity: 0.3;
  }
  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  .about-container {
    position: relative; 
    z-index: 1;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
    color: #ffffff;
  }


  
  h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #3c88d3;
    text-align: center;
  }
  
  .geoverse-highlight {
    color: #3498db;
    font-weight: bold;
  }
  
  .game-name {
    color: #e67e22;
    font-weight: bold;
  }

  .game-name a {
    text-decoration: underline;
    color: inherit; /* Optional: Keep the color the same as the parent span */
  }
  
  .sentence-start {
    color: #50b9ff;
    font-weight: bold;
    font-size: 1.5em;
  }
  
  p {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    .about-container {
      padding: 15px;
    }
  
    h1 {
      font-size: 2em;
    }
  
    p {
      font-size: 1em;
    }
  }
  