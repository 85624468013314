.scripts-container h2 {
  margin-bottom: 10px; /* Add margin to the bottom of headers */
}

.code-example-container {
  margin: 20px 0;
  text-align: center;
  max-width: 60vh; /* Constrain the width of the whole component */
  margin-left: auto;
  margin-right: auto;
  position: relative; /* Ensure the container can position child elements absolutely */
  padding-bottom: 50px; /* Add padding to the bottom to make space for like/dislike and comment buttons */
}
  
  .code-name {
    font-size: 1.7em; /* Adjust font size for the code name */
  }
  
  .metadata-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align items to the start */
    font-size: 0.5em; /* Smaller font size for metadata */
    margin-bottom: 10px;
  }
  
  .metadata-left {
    display: flex;
    flex-direction: column;
  }


  
  .metadata-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .code-author {
    margin-top: 5px; /* Add some space between controls and author */
  }
  
  .code-controls {
    display: flex;
    gap: 5px;
  }
  
  .code-controls .fa-search-plus,
  .code-controls .fa-search-minus {
    cursor: pointer;
  }
  
  .zoomable-code-container {
    position: relative;
    width: 100%;
    display: inline-block;
    text-align: left;
  }
  
  .code-wrapper {
    overflow: auto;
    height: 40vh; /* Fixed height relative to the viewport height */
    max-width: 60vh;
    padding: 10px;
    background-color: #2d2d2d; /* Match the background color of your syntax highlighter */
    border-radius: 5px;
    white-space: nowrap; /* Prevent the code from wrapping */
    position: relative;
  }
  
  .description-container {
    text-align: center;
    margin-top: 10px;
    max-width: 60vh;
    margin-left: auto;
    margin-right: auto;
  }
  
  .description-label {
    font-size: 0.7em; /* Bigger font size for the description label */
    margin: 0;
  }

  .code-description {
    font-size: 0.5em; /* Smaller font size for description */
    text-align: center;
    margin-top: 10px;
    max-width: 60vh; /* Ensure it wraps within the container width */
    margin-left: auto;
    margin-right: auto;
    white-space: normal; /* Ensure the text wraps */
    word-wrap: break-word; /* Ensure long words break within the container */
  }

  .like-dislike {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }


  
  .comment-section {
    text-align: center;
    margin-top: 10px;
  }
  
  .comment-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .comment-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
  
  .comment-form textarea {
    width: 90%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .comment-form button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .comments-container {
    margin-top: 10px;
    text-align: left;
  }
  
  .comment {
    background-color: #303030;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
  }

  
  