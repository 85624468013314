.like-dislike-container {
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 10px; /* Adjust gap between like and dislike sections */
  }
  
  .like-container,
  .dislike-container {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .like-button,
  .dislike-button {
    cursor: pointer;
    font-size: 0.8em; /* Adjust icon size to be smaller */
    color: #007bff; /* Adjust color for like button */
  }
  
  .dislike-button {
    color: #dc3545; /* Adjust color for dislike button */
  }
  