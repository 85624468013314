  .apply-background {
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../resources/zelda.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    z-index: 0; /* Ensure it sits behind the content */
    opacity: 0.3;
  }
  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  .about-container {
    position: relative; 
    z-index: 1;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
    background-color: #363535a9;
    color: #ffffff;
  }

  p {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 20px;
  }
